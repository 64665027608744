import React from 'react';
import Fade from "react-reveal/Fade"


const Crime = () => (
  <Fade top>
    <section className="content-block block-margin-top ">
      <div className="firm">
     <h1> OUR MISSION</h1> 
      </div>   
    </section>
    </Fade>
)

export default Crime